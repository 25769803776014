<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../../helper/Gen';

	export default {
		extends: BaseVue,
		data() {},
		mounted() {},
		methods: {},
		watch: {},
	};
</script>

<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap notoppadding pt-0 pb-0" style="padding-top: 0px!important;">
			<div id="sectionNotFound" class="section notopmargin  nobottommargin page-section bg_white clearfix" style="background-color: #f1f0f0;">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-10">
							<div class="not-found">
								<img :src="assets('fo_images','404.png')" alt="404 Not Found" title="404 Not Found">
								<div class="heading">
									<h3>It Looks Like You Lost Your Way with an Empty Glass.</h3>
									<span>Don't Worry, We'll Help You Fill Up Your Glass.</span>
								</div>
								<p>
									<router-link :to="{name:'Product'}" href="javascript:;" class="btn_line">Wine</router-link>
									<router-link :to="{name:'Product2'}" href="javascript:;" class="btn_line">Spirits - Liquer
									</router-link>
									<router-link :to="{name:'Product3'}" href="javascript:;" class="btn_line">Water</router-link>
								</p>
								<router-link :to="{name:'Home'}">
									{{ web.lbl_back_to_home }}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>